import axios from "axios";
import {
  doc,
  getDoc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { db } from "../../firebase/firebase";
import { OutlinedBox } from "../../styles";

const Forex = ({ action, user }) => {
  const [accounts, setAccounts] = useState([]);
  const pairs = [
    { name: "EUR/USD", symbol: "EUR/USD" },
    { name: "GBP/USD", symbol: "GBP/USD" },
    { name: "USD/CHF", symbol: "USD/CHF" },
    { name: "USD/CAD", symbol: "USD/CAD" },
    { name: "NZD/USD", symbol: "NZD/USD" },
    { name: "USD/JPY", symbol: "USD/JPY" },
    { name: "AUD/USD", symbol: "AUD/USD" },
    { name: "USD/GBP", symbol: "USD/GBP" },
    { name: "EUR/CAD", symbol: "EUR/CAD" },
    { name: "EUR/AUD", symbol: "EUR/AUD" },
    { name: "EUR/JPY", symbol: "EUR/JPY" },
    { name: "EUR/CHF", symbol: "EUR/CHF" },
    { name: "EUR/GBP", symbol: "EUR/GBP" },
    { name: "AUD/CAD", symbol: "AUD/CAD" },
    { name: "GBP/CHF", symbol: "GBP/CHF" },
    { name: "GBP/JPY", symbol: "GBP/JPY" },
    { name: "CHF/JPY", symbol: "CHF/JPY" },
    { name: "AUD/JPY", symbol: "AUD/JPY" },
    { name: "AUD/NZD", symbol: "AUD/NZD" },
  ];
  const [pair, setPair] = useState(pairs[0].symbol);
  const [fiatAccount, setFiatAccount] = useState([]);
  const [balance, setBalance] = useState("");
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [marginError, setMarginError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pairPrice, setPairPrice] = useState("");
  const [quote, setQuote] = useState("");
  const [base, setBase] = useState("");
  const [USDBase, setUSDBase] = useState("");
  const [tp, setTP] = useState("");
  const [sl, setSL] = useState("");
  const [tpBuyError, setTPBuyError] = useState(false);
  const [tpSellError, setTPSellError] = useState(false);
  const [slBuyError, setSLBuyError] = useState(false);
  const [slSellError, setSLSellError] = useState(false);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const currentAccount = "live";
  const [liveAccount, setLiveAccount] = useState([]);
  const [practiceAccount, setPracticeAccount] = useState([]);
  const [id, setID] = useState("");
  const amountRef = useRef();
  const tpRef = useRef();
  const slRef = useRef();
  const [time, setTime] = useState(5);
  const [margin, setMargin] = useState("");

  useEffect(() => {
    async function getData() {
      const docRef = doc(db, "accounts", user);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setAccounts(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }

    getData();
  }, []);

  // For JPY Quote
  // Rest

  // For JPY Quotes, pip value = 0.01 * Size(Amount * 1000) / Current Price.toFixed(2)
  // For Others, pip value = 0.0001 * Size(Amount * 1000) / Current Price.toFixed(4)

  // Pair to USD

  async function fetchForexPrice(pair) {
    const pairsSplit = pair.split("/");
    const Base = pairsSplit[0];
    const Quote = pairsSplit[1];
    setBase(Base);
    setQuote(Quote);
    const url = `https://api.fastforex.io/fetch-one?from=${Base}&to=${Quote}&api_key=18bfe4aa78-c27909660a-rd9m18`;

    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { result } = response.data;
        setPairPrice(
          Quote === "JPY"
            ? Number(...Object.values(result)).toFixed(2)
            : Number(...Object.values(result)).toFixed(4)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    async function baseToUSD(curr) {
      const url = `https://api.fastforex.io/fetch-one?from=${curr}&to=USD&api_key=18bfe4aa78-c27909660a-rd9m18`;

      const config = {
        method: "get",
      };

      try {
        const response = await axios.get(url, config);
        if (response) {
          const { result } = response.data;
          setUSDBase(...Object.values(result));
        }
      } catch (error) {
        console.log("error", error);
      }
    }

    if (base) {
      baseToUSD(base);
    }
  }, [base]);

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      if (live && practice) {
        setLiveAccount(live);
        setPracticeAccount(practice);
        fetchForexPrice(pair);
      }
    } else {
      return;
    }
  }, [accounts]);

  useEffect(() => {
    if (user) {
      setID(user);
    }
  }, [user]);

  useEffect(() => {
    if (currentAccount && liveAccount && practiceAccount) {
      switch (currentAccount) {
        case "live":
          setFiatAccount(liveAccount.Fiat);
          break;
        case "practice":
          setFiatAccount(practiceAccount.Fiat);
          break;
        default:
          break;
      }
    }
  }, [currentAccount, liveAccount, practiceAccount]);

  useEffect(() => {
    if (fiatAccount) {
      setBalance(fiatAccount.value);
    }
  }, [fiatAccount]);

  function handlePairChange(e) {
    const value = e.target.value;
    setPair(value);
    reset();
  }

  useEffect(() => {
    if (pair) {
      fetchForexPrice(pair);
    }
  }, [pair]);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }

    if (value > balance) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  }

  function handleTP(e) {
    const value = e.target.value;
    if (value > 0) {
      setTP(value);
    } else {
      setTP("");
    }

    if (tp && action === "buy" && value <= pairPrice) {
      setTPBuyError(true);
    } else {
      setTPBuyError(false);
    }

    if (tp && action === "sell" && value >= pairPrice) {
      setTPSellError(true);
    } else {
      setTPSellError(false);
    }
  }

  function handleSL(e) {
    const value = e.target.value;
    if (value > 0) {
      setSL(value);
    } else {
      setSL("");
    }

    if (sl && action === "buy" && value >= pairPrice) {
      setSLBuyError(true);
    } else {
      setSLBuyError(false);
    }

    if (sl && action === "sell" && value <= pairPrice) {
      setSLSellError(true);
    } else {
      setSLSellError(false);
    }
  }

  function handleTimeInForce(e) {
    const value = e.target.value;
    const timeSlice = value.slice(0, value.indexOf(" "));
    setTime(Number(timeSlice));
  }

  function reset() {
    setAmount("");
    setAmountError(false);
    setTPBuyError(false);
    setTPSellError(false);
    setSLBuyError(false);
    setSLSellError(false);
    setMargin("");

    amountRef.current.value = "";

    if (slRef) {
      slRef.current.value = "";
    }

    if (tpRef) {
      tpRef.current.value = "";
    }
  }

  useEffect(() => {
    setMargin(
      Number(((1000 * Number(amount)) / 30) * Number(pairPrice)).toFixed(2)
    );
  }, [amount, pairPrice]);

  useEffect(() => {
    if (margin > balance) {
      setMarginError(true);
    } else {
      setMarginError(false);
    }
  }, [margin, balance]);

  const handleTrade = () => {
    const tradeAction = action.slice(0, 1).toUpperCase() + action.slice(1);

    setIsSubmitting(true);

    const pairsSplit = pair.split("/");
    const Base = pairsSplit[0];
    const Quote = pairsSplit[1];

    const valuePerPip =
      Quote === "JPY"
        ? (
            ((0.01 * (Number(amount) * 1000)) / Number(pairPrice).toFixed(2)) *
            Number(USDBase).toFixed(2)
          ).toFixed(2)
        : (
            ((0.0001 * (Number(amount) * 1000)) /
              Number(pairPrice).toFixed(4)) *
            Number(USDBase)
          ).toFixed(2);

    const details = {
      advanced: isAdvanced ? true : false,
      margin,
      action: tradeAction,
      account: currentAccount,
      tp,
      sl,
      entry: pairPrice,
      pair,
      time,
      perpip: valuePerPip,
      amount,
    };

    decrementAmount(
      pair,
      amount,
      id,
      pairPrice,
      "Forex",
      tradeAction,
      margin,
      details
    );
  };

  // Decrement Margin
  const decrementAmount = async (
    pair,
    amount,
    id,
    pairPrice,
    type,
    action,
    margin,
    details
  ) => {
    const q = doc(db, "accounts", id);
    const key = `${currentAccount}.Fiat.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-Number(margin)),
      }).then(() => {
        submitTrade(pair, amount, id, pairPrice, type, action, margin, details);
      });
    } catch (error) {
      console.log(error);
    }
  };

  //submit Crypto Order Placement
  async function submitTrade(
    pair,
    amount,
    id,
    pairPrice,
    type,
    action,
    margin,
    details
  ) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "trades", str), {
      date: serverTimestamp(),
      ref: str,
      status: "open",
      type,
      user: id,
      amount,
      asset: pair,
      price: pairPrice,
      action,
      margin,
      details,
    })
      .then(() => {
        postTrade(id, str, details);
        // reset();
        // setIsSubmitting(false);
      })
      .catch((error) => {
        toast.error("Order Could Not Complete.");
      });
  }

  async function postTrade(user, str, details) {
    const url = "https://fin-http-production.up.railway.app/stockmarketaccess/forex";

    const base = {
      user,
      ref: str,
      details,
    };

    const config = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(base),
    };

    await fetch(url, config)
      .then((response) => {
        if (response) {
          toast.success("Trade sucessfully placed.");
          setIsSubmitting(false);
          reset();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  return (
    <Wrapper>
      <div className="selectWrap">
        <label htmlFor="pair">Asset</label>
        <div className="selectBox">
          <div className="selectTop">
            {/* <span> */}
            <select name="asset" onChange={handlePairChange}>
              {pairs.map((par) => (
                <option key={par.symbol}>{par.symbol}</option>
              ))}
            </select>
            {/* <img src="/extraicons/arrowdown.svg" alt="select" /> */}
            {/* </span> */}
          </div>

          <div className="captions">
            {pairPrice && (
              <p className="balance">
                Balance ~
                <span
                  className={Number(fiatAccount.value) > 0 ? "green" : "red"}
                >
                  {fiatAccount.value > 0 ? (
                    "$" + Number(fiatAccount.value).toFixed(2)
                  ) : (
                    <span className="red">
                      0 USD{" "}
                      <Link to="/deposit" className="red">
                        Deposit now
                      </Link>{" "}
                    </span>
                  )}
                </span>
              </p>
            )}

            {pairPrice && (
              <p className="balance">
                Current Price <span className="green">~${pairPrice}</span>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="amWrap">
        <label htmlFor="amount">Amount(K)</label>
        <div className="amBx">
          <div className="amTop p-2 pl-0">
            <input
              ref={amountRef}
              placeholder="1000"
              name="amount"
              type="number"
              style={{ padding: "1rem" }}
              onChange={handleAmount}
            />
          </div>

          <div className="captions">
            <p className="balance">
              {amount && <span>Req Margin - ${margin}</span>}
            </p>

            {amount && (
              <p className="extra">
                Per pip -
                <span className="green">
                  {" "}
                  {quote === "JPY"
                    ? (
                        ((0.01 * (Number(amount) * 1000)) /
                          Number(pairPrice).toFixed(2)) *
                        USDBase
                      ).toFixed(2)
                    : (
                        ((0.0001 * (Number(amount) * 1000)) /
                          Number(pairPrice).toFixed(4)) *
                        USDBase
                      ).toFixed(2)}
                </span>
              </p>
            )}
          </div>

          {amountError && (
            <p className="red warning textMedium" style={{ marginTop: "1rem" }}>
              Trade amount cannot exceed current balance. Add to this user's USD
              balance to continue.
            </p>
          )}
          {marginError && (
            <p className="red warning textMedium" style={{ marginTop: "1rem" }}>
              Add to this user's USD balance to continue.
            </p>
          )}
        </div>
      </div>

      <div className="selectWrap">
        <label htmlFor="timeinforce">Time in Force</label>
        <div className="selectBox">
          <div className="selectTop">
            <select name="timeinforce" onChange={handleTimeInForce}>
              <option>5 minutes</option>
              <option>10 minutes</option>
              <option>15 minutes</option>
              <option>30 minutes</option>
            </select>
            <img src="/extraicons/arrowdown.svg" alt="select" />
          </div>
        </div>
      </div>

      <OutlinedBox style={{ padding: "0.5rem", margin: "0", width: "100%" }}>
        <span
          className="infot"
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "360px",
          }}
        >
          {isAdvanced
            ? " Your trade will auto close if SL or TP does not hit in " +
              time +
              " minutes."
            : "Your trade will auto close in " + time + " minutes."}
        </span>
      </OutlinedBox>

      {/* <OutlinedBox style={{ padding: "0.5rem" }}>
        <p className="infot">
          All unresolved trades will auto close by the end of the trading day.
        </p>
      </OutlinedBox> */}

      <button
        disabled={
          !amount ||
          !pair ||
          !pairPrice ||
          !balance ||
          amountError ||
          tpBuyError ||
          tpSellError ||
          slBuyError ||
          slSellError ||
          !margin ||
          !time
        }
        className={
          !amount ||
          !pair ||
          !pairPrice ||
          !balance ||
          amountError ||
          tpBuyError ||
          tpSellError ||
          slBuyError ||
          slSellError ||
          !margin ||
          !time
            ? "button disabled"
            : "button submit"
        }
        onClick={handleTrade}
        style={{
          placeContent: "center",
          display: "flex",
          alignItems: "center",
          gap: "3px",
        }}
      >
        {isSubmitting ? (
          <img
            src="/svg-loaders/tail-spin.svg"
            alt="loading"
            width={24}
            height={24}
          />
        ) : (
          <>
            Place <span style={{ textTransform: "capitalize" }}>{action}</span>{" "}
            Order
          </>
        )}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 0.8rem;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

export default Forex;
