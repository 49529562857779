import { Menu, OutlinedButton, ProfileCard, SubText } from "../styles";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  collection,
  query,
  getDocs,
  orderBy,
  updateDoc,
  increment,
  doc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import { Link, useNavigate } from "react-router-dom";
import { ClickAwayListener, Grow } from "@mui/material";
import ModalImage from "react-modal-image";
import { userContext } from "../context/userContext";
import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";

const SignalPaymentsTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [showActions, setShowActions] = useState(false);
  const [action, setAction] = useState("");
  const { currentPrices } = useContext(userContext);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [id, setID] = useState("");

  useEffect(() => {
    const paymentsList = [];
    async function getPayments() {
      const q = query(
        collection(db, "signalDeposits"),
        orderBy("date", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        paymentsList.push(doc.data());
        setPayments(paymentsList);
      });
    }

    getPayments();
  }, []);

  useEffect(() => {
    if (!payments) {
      setIsLoading(true);
    }

    if (payments) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [payments]);

  function getTime(milliSeconds) {
    const seconds = milliSeconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (seconds > 60 && minutes < 60) {
      return (
        Math.floor(minutes) +
        (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours < 24) {
      return (
        Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
      return (
        Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
      return (
        Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
      );
    }

    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
    );
  }

  useEffect(() => {
    const userList = [];
    async function getUsers() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        userList.push(doc.data());
        setUsers(userList);
      });
    }

    getUsers();
  }, []);

  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    }

    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [users]);

  const cardRef = useRef();
  const [activeRef, setActiveRef] = useState("");

  function handlePayment(user, type, amount, ref) {
    console.log(ref);
    setActiveRef(ref);
    setID(user);
  }

  const [approvedPayments, setApprovedPayments] = useState([]);
  const [declinedPayments, setDeclinedPayments] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);

  useEffect(() => {
    const approvedPaymentsList = [];
    const pendingPaymentsList = [];
    const declinedPaymentsList = [];

    payments.forEach((payment) => {
      switch (payment.status) {
        case "pending":
          pendingPaymentsList.push(payment);
          break;
        case "approved":
          approvedPaymentsList.push(payment);
          break;
        case "declined":
          declinedPaymentsList.push(payment);
          break;
        default:
          break;
      }
    });

    setApprovedPayments(approvedPaymentsList);
    setPendingPayments(pendingPaymentsList);
    setDeclinedPayments(declinedPaymentsList);
  }, [payments, approvedPayments, declinedPayments, pendingPayments]);

  useEffect(() => {
    if (id) {
      users.forEach((us) => {
        if (us.id === id) {
          setUserEmail(us.email);
          setUserName(us.firstname);
        }
      });
    }
  }, [users, id]);

  // Determines if approve, withdraw or request and calls the functions
  function handleAction(act, ref, user, type, amount) {
    setActiveRef("");
    setAction(ref);
    if (act === "approve") {
      // sendApproveEmail(
      //   ref,
      //   user,
      //   userEmail,
      //   userName,
      //   type,
      //   Number(amount).toFixed(3)
      // );
      handleApprove(ref, user, type, Number(amount).toFixed(2));
    }

    if (act === "decline") {
      // sendDeclineEmail(
      //   ref,
      //   user,
      //   userEmail,
      //   userName,
      //   type,
      //   Number(currentPrices[type] / amount).toFixed(3)
      // );
      handleDecline(ref, user, type, Number(amount).toFixed(2));
    }

    if (act === "request") {
      // sendRequestEmail(
      //   ref,
      //   user,
      //   userEmail,
      //   userName,
      //   type,
      //   Number(currentPrices[type] / amount).toFixed(3),
      //   act
      // );
      handleDecline(ref, user, type, Number(amount).toFixed(2));
    }
  }

  // Approval Email and calls to change doc status
  async function sendApproveEmail(ref, user, email, name, type, amount) {
    const params = {
      action: "Deposit",
      final: "Completed",
      user_name: name,
      amount: amount,
      logo_link: "https://stockmarketaccess.net/logo.png",
      action_name: "deposit",
      asset: type,
      to_or_from: "to",
      from_name: "Stock Market Access",
      accountType: "crypto",
      message: "has been completed",
      detail: `${amount} ${type} should be in your account soon.`,
      broker_web: "https://stockmarketaccess.net",
      broker_web_to: "stockmarketaccess.net",
      broker_email_to: "mailto:support@stockmarketaccess.net",
      to_email: email,
      from_email: "support@stockmarketaccess.net",
      reply_to: "support@stockmarketaccess.net",
    };
    emailjs
      .send("service_wqqc79q", "template_vck7wtq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        handleApprove(ref, user, type, amount);
      })
      .catch((error) => {
        toast.error("There was an error with this approval");
        console.log(error);
        setAction("");
      });
  }

  // Increments the user account balance and calls to send notification
  async function handleUserApprove(user, type, amount) {
    const q = doc(db, "balance", user);
    try {
      await updateDoc(q, {
        signalBalance: increment(
          Number(currentPrices[type] * amount).toFixed(2)
        ),
      }).then(() => {
        setApproveNotification(user, type, amount);
      });
    } catch (error) {
      console.log(error);
    }
  }

  //send approve notification
  async function setApproveNotification(user, type, amount) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();
    const amountUSD = Number(currentPrices[type] * amount).toFixed(2);

    await setDoc(doc(db, "notifications", str), {
      date: serverTimestamp(),
      ref: str,
      user,
      title: "Deposit",
      text: "Your signal balance was credited with $" + amountUSD,
    })
      .then(() => {
        toast.success("Approved successfully");
        setAction("");
      })
      .catch((error) => {
        toast.error("Approval could not complete.");
      });
  }

  // changes the status of the deposit doc and calls to increment the user's balance
  async function handleApprove(ref, user, type, amount) {
    const selected = doc(db, "signalDeposits", ref);
    await updateDoc(selected, {
      status: "approved",
    })
      .then(() => {
        handleUserApprove(user, type, amount);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setAction("");
      });
  }

  // Decline Email and calls to change doc status
  async function sendDeclineEmail(ref, user, email, name, type, amount) {
    const params = {
      action: "Deposit",
      final: "Declined",
      user_name: name,
      amount: amount,
      logo_link: "https://stockmarketaccess.net/logo.png",
      action_name: "deposit",
      asset: type,
      to_or_from: "to",
      from_name: "Stock Market Access",
      accountType: "crypto",
      message: "has been declined",
      detail:
        "Kindly contact your account manager or chat support for further information.",
      broker_web: "stockmarketaccess.net",
      broker_email_to: "mailto:support@stockmarketaccess.net",
      to_email: email,
      from_email: "support@stockmarketaccess.net",
      reply_to: "support@stockmarketaccess.net",
    };

    emailjs
      .send("service_wqqc79q", "template_vck7wtq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        handleDecline(ref, user, type, amount);
      })
      .catch((error) => {
        toast.error("An error occured. Please try again later");
        console.log(error);
        setAction("");
      });
  }

  // changes the status of the deposit doc and calls notification
  async function handleDecline(ref, user, type, amount, act) {
    const selected = doc(db, "signalDeposits", ref);
    await updateDoc(selected, {
      status: "declined",
    })
      .then(() => {
        setDeclineNotification(user, type, amount, act);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setAction("");
      });
  }

  //send decline notification
  async function setDeclineNotification(user, type, amount, act) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    const amountUSD = Number(currentPrices[type] * amount).toFixed(2);
    await setDoc(doc(db, "notifications", str), {
      date: serverTimestamp(),
      ref: str,
      user,
      title: "Deposit",
      text: "Your deposit of $" + amountUSD + " failed.",
    })
      .then(() => {
        {
          act === "request"
            ? toast.success("Requested further proof")
            : toast.success("Declined successfully");
        }
        setAction("");
      })
      .catch((error) => {
        toast.error("Decline request could not complete.");
      });
  }

  // Send Request Email and calls to change doc status
  async function sendRequestEmail(ref, user, email, name, type, amount, act) {
    const params = {
      action_desc: "deposit proof",
      Action: "Deposit",
      user_name: name,
      message: "deposit into your account",
      logo_link: "https://stockmarketaccess.net/logo.png",
      detail: "Kindly login to your account to complete this request.",
      login_to: "https://app.stockmarketaccess.net",
      from_name: "Stock Market Access",
      website: "https://stockmarketaccess.net",
      email_to: "mailto:support@stockmarketaccess.net",
      to_email: email,
      from_email: "support@stockmarketaccess.net",
      reply_to: "support@stockmarketaccess.net",
    };

    emailjs
      .send("service_wqqc79q", "template_232iwnq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {
        handleDecline(ref, user, type, amount, act);
      })
      .catch((error) => {
        toast.error("There was an error with this request.");
        console.log(error);
        setAction("");
      });
  }

  return (
    <Wrapper>
      <SubText className="top">Signal Deposits</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <table className="scrollbar-hidden">
          <thead>
            <tr>
              <th>User</th>
              <th>Ref</th>
              <th>Method</th>
              <th>Amount</th>
              <th>Date</th>
              {/* <th className="proof">Proof</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pendingPayments.map(
              (payment) =>
                payment.status === "pending" && (
                  <tr key={payment.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === payment.user && (
                            <div className="name user" key={payment.ref}>
                              {user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={user.photoURL}
                                    alt="user"
                                    className="profile"
                                  />
                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "0",
                                        right: "0",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              {!user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <span className="avatar">
                                    {user.firstname.slice(0, 1)}
                                  </span>

                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-4px",
                                        right: "-4px",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              <p>{user.firstname}</p>
                            </div>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{payment.ref}</p>
                    </td>
                    <td>
                      <p>{payment.type}</p>
                    </td>
                    <td>
                      <p>{payment.amount}</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - payment.date.toDate())}</p>
                      {/* {user.isVerified && <button>active</button>} */}
                      {/* {!user.isVerified && <button>passive</button>} */}
                    </td>
                    {/* <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={payment.proof}
                        alt="deposit"
                        className="modal"
                      />
                    </td> */}
                    <td>
                      {payment.status === "pending" && (
                        <div style={{ position: "relative" }}>
                          <button
                            disabled={action}
                            ref={cardRef}
                            className="pending"
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              padding: "0.21rem",
                              display: "grid",
                              placeContent: "center",
                              height: "42px",
                            }}
                            onClick={() =>
                              handlePayment(
                                payment.user,
                                payment.type,
                                payment.amount,
                                payment.ref
                              )
                            }
                          >
                            {action === payment.ref ? (
                              <span
                                className="flex w-full"
                                style={{ placeContent: "center" }}
                              >
                                <img
                                  src="/svg-loaders/tail-spin.svg"
                                  alt="loading"
                                  width={24}
                                  height={24}
                                />
                              </span>
                            ) : (
                              <p>Pending</p>
                            )}
                          </button>
                          {activeRef === payment.ref && (
                            <>
                              <ClickAwayListener
                                onClickAway={() => setActiveRef("")}
                              >
                                <Grow
                                  in={activeRef === payment.ref}
                                  style={{ transformOrigin: "0 0 0 0" }}
                                  {...(activeRef === payment.ref
                                    ? { timeout: 300 }
                                    : {})}
                                >
                                  <div className="actionMenu">
                                    <p
                                      onClick={() =>
                                        handleAction(
                                          "approve",
                                          payment.ref,
                                          payment.user,
                                          payment.type,
                                          payment.amount
                                        )
                                      }
                                    >
                                      Approve
                                    </p>
                                    <p
                                      onClick={() =>
                                        handleAction(
                                          "decline",
                                          payment.ref,
                                          payment.user,
                                          payment.type,
                                          payment.amount
                                        )
                                      }
                                    >
                                      Decline
                                    </p>
                                    <p
                                      onClick={() =>
                                        handleAction(
                                          "request",
                                          payment.ref,
                                          payment.user,
                                          payment.type,
                                          payment.amount
                                        )
                                      }
                                    >
                                      Request more proof
                                    </p>
                                  </div>
                                </Grow>
                              </ClickAwayListener>
                            </>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                )
            )}
            {approvedPayments.map(
              (payment) =>
                payment.status === "approved" && (
                  <tr key={payment.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === payment.user && (
                            <div className="name user" key={payment.ref}>
                              {user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={user.photoURL}
                                    alt="user"
                                    className="profile"
                                  />
                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "0",
                                        right: "0",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              {!user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <span className="avatar">
                                    {user.firstname.slice(0, 1)}
                                  </span>

                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-4px",
                                        right: "-4px",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              <p>{user.firstname}</p>
                            </div>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{payment.ref}</p>
                    </td>
                    <td>
                      <p>{payment.type}</p>
                    </td>
                    <td>
                      <p>{payment.amount}</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - payment.date.toDate())}</p>
                      {/* {user.isVerified && <button>active</button>} */}
                      {/* {!user.isVerified && <button>passive</button>} */}
                    </td>
                    {/* <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={payment.proof}
                        alt="deposit"
                        className="modal"
                      />
                    </td> */}
                    <td
                      style={{
                        placeContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      {payment.status === "approved" && (
                        <button
                          disabled
                          className="approved"
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            padding: "0.21rem",
                          }}
                        >
                          <p>Approved</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}
            {declinedPayments.map(
              (payment) =>
                payment.status === "declined" && (
                  <tr key={payment.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === payment.user && (
                            <div className="name user" key={payment.ref}>
                              {user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={user.photoURL}
                                    alt="user"
                                    className="profile"
                                  />
                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "0",
                                        right: "0",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              {!user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <span className="avatar">
                                    {user.firstname.slice(0, 1)}
                                  </span>

                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-4px",
                                        right: "-4px",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              <p>{user.firstname}</p>
                            </div>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{payment.ref}</p>
                    </td>
                    <td>
                      <p>{payment.type}</p>
                    </td>
                    <td>
                      <p>{payment.amount}</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - payment.date.toDate())}</p>
                    </td>
                    {/* <td
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      <button
                        style={{
                          pointerEvents: "none",
                        }}
                        className="actionButton"
                      >
                        <p className="actionText">View</p>
                      </button>
                      <ModalImage
                        large={payment.proof}
                        alt="deposit"
                        className="modal"
                      />
                    </td> */}
                    <td
                      style={{
                        placeContent: "flex-end",
                        display: "flex",
                      }}
                    >
                      {payment.status === "declined" && (
                        <button
                          disabled
                          className="declined"
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            padding: "0.21rem",
                          }}
                        >
                          <p>Declined</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .avatar {
    width: 32px;
    place-content: center;
    height: 32px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .actionMenu {
    appearance: none;
    position: absolute;
    left: -100px;
    z-index: 1000;
    top: -100px;
    /* bottom: -40px; */
    border: 0.5px solid #1f273a;
    background: #161c2a;
    border-radius: 8px;
    padding: 0.4rem;
    display: grid;
    gap: 0.5rem;

    p {
      padding: 0.5rem;
      cursor: pointer;
      transition: background-color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    p:hover {
      background-color: #1f273a;
      border-radius: 4px;
    }
  }

  .action {
    align-self: flex-end;
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;
    padding-bottom: 30px;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default SignalPaymentsTable;
