import styled from "styled-components";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useContext, useEffect, useRef, useState } from "react";
import { TitleText, BodyText, OutlinedBox } from "../styles";
import { Card, SubText, Content } from "../styles";
import { Table } from "../components";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { useMediaQuery } from "@mui/material";
import { userContext } from "../context/userContext";
import {
  doc,
  increment,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import toast from "react-hot-toast";
import WithdrawalHistory from "../usertables/WithdrawalHistory";
import emailjs from "@emailjs/browser";

const Withdraw = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 800px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const withdrawalTypes = ["Crypto", "Bank Transfer", "Paypal"];
  const [withdrawalType, setWithdrawalType] = useState("Crypto");
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const [asset, setAsset] = useState("BTC");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { accounts, currentPrices } = useContext(userContext);
  const [liveAccounts, setLiveAccounts] = useState([]);
  const [amount, setAmount] = useState("");
  const [cryptoAmountError, setCryptoAmountError] = useState(false);
  const [bankAmountError, setBankAmountError] = useState(false);
  const [balance, setBalance] = useState(200);
  const [address, setAddress] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [paypalAddress, setPaypalAddress] = useState("");
  const [paypalAmountError, setPaypalAmountError] = useState("");
  const cryptoAmountRef = useRef();
  const paypalAmountRef = useRef();
  const bankAmountRef = useRef();
  const cryptoAddressRef = useRef();
  const bankAccountNameRef = useRef();
  const bankAccountNumberRef = useRef();
  const bankAccountRef = useRef();
  const paypalAddressRef = useRef();
  const { userData } = useContext(userContext);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Stock, Crypto, Fiat } = live;
        setBalance(Crypto.BTC.value);
        const StockAccount = Object.values(Stock);
        const CryptoAccount = Object.values(Crypto);
        setLiveAccounts([...CryptoAccount, ...StockAccount, Fiat]);
      }
    }
  }, [accounts]);

  function handleCryptoAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
    if (value > balance) {
      setCryptoAmountError(true);
    } else {
      setCryptoAmountError(false);
    }
  }

  async function sendAdminEmail() {
    const params = {
      details: `A new withdrawal was just requested on your platform.`,
      action_name: "Withdrawal",
      to_email: "support@stockmarketaccess.net",
      from_name: "Notifications",
      logo_link: "https://stockmarketaccess.net/logo.png",
      to_name: "Admin",
      broker_to_link: "https://app.stockmarketaccess.net",
      broker_name: "Stock Market Access",
    };

    emailjs
      .send("service_x4dbltd", "template_kkyhhxq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  function handleBankAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
    if (value > balance) {
      setBankAmountError(true);
    } else {
      setBankAmountError(false);
    }
  }

  function handlePaypalAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
    if (value > balance) {
      setPaypalAmountError(true);
    } else {
      setPaypalAmountError(false);
    }
  }

  async function submitCryptoWithdrawal() {
    // console.log("yeahh");
    // setIsSubmitting(false);

    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);
    // console.log(randomOne, randomTwo);

    const str =
      user.uid.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "withdrawals", str), {
      ref: str,
      user: user.uid,
      status: "pending",
      date: serverTimestamp(),
      amount: Number(amount),
      type: withdrawalType,
      asset,
      details: [
        {
          name: "Asset",
          value: asset,
        },
        {
          name: "Address",
          value: address,
        },
      ],
    })
      .then(() => {
        clear();
        sendAdminEmail();
        toast.success("Withdrawal request submitted");
        setIsSubmitting(false);
      })
      .catch((error) => {
        // console.log(error.message);
        toast.error("There was a problem. Please try again later");
      });
  }

  async function submitWithdrawal() {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    let details;

    switch (withdrawalType) {
      case "Bank Transfer":
        details = [
          {
            name: "Account Name",
            value: accountName,
          },
          {
            name: "Account Number",
            value: accountNumber,
          },
          {
            name: "Bank Name",
            value: bankName,
          },
        ];
        break;
      case "Paypal":
        details = [
          {
            name: "Paypal Address",
            value: paypalAddress,
          },
        ];
        break;
      default:
        break;
    }

    const str =
      user.uid.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "withdrawals", str), {
      ref: str,
      user: user.uid,
      status: "pending",
      date: serverTimestamp(),
      amount: Number(amount),
      type: withdrawalType,
      details,
      asset: "USD",
    })
      .then(() => {
        clear();
        sendAdminEmail();
        toast.success("Withdrawal request submitted");
        setIsSubmitting(false);
      })
      .catch((error) => {
        // console.log(error.message);
        toast.error("There was a problem. Please try again later");
      });
  }

  // Decrement crypto
  const decrementCrypto = async (asset, amount) => {
    const q = doc(db, "accounts", user.uid);
    const key = `live.Crypto.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-amount),
      }).then(() => {
        submitCryptoWithdrawal();
      });
    } catch (error) {
      console.log(error);
    }
  };

  function handleCryptoWithdrawal() {
    setIsSubmitting(true);
    decrementCrypto(asset, Number(amount));
  }

  // Decrement fiat
  const decrementAmount = async (amount) => {
    const q = doc(db, "accounts", user.uid);
    try {
      await updateDoc(q, {
        "live.Fiat.value": increment(-amount),
      }).then(() => {
        submitWithdrawal();
      });
    } catch (error) {
      console.log(error);
    }
  };

  function handlePaypalWithdrawal() {
    setIsSubmitting(true);
    decrementAmount(Number(amount));
  }

  function handleBankWithdrawal() {
    setIsSubmitting(true);
    decrementAmount(Number(amount));
  }

  useEffect(() => {
    liveAccounts.forEach((acc) => {
      if (acc.asset === asset) {
        setBalance(acc.value);
      }
    });

    return () => {
      setBalance("");
    };
  }, [asset, liveAccounts]);

  function handleAssetChange(e) {
    const value = e.target.value;
    setAsset(value);
    clear();
  }

  function clear() {
    setAmount("");
    setAccountNumber("");
    setAddress("");
    setBankName("");
    setAccountNumber("");
    setPaypalAddress("");
    setBankAmountError(false);
    setPaypalAmountError(false);
    setCryptoAmountError(false);

    if (cryptoAddressRef.current) {
      cryptoAddressRef.current.value = "";
    }

    if (bankAccountRef.current) {
      bankAccountRef.current.value = "";
    }

    if (bankAccountNameRef.current) {
      bankAccountNameRef.current.value = "";
    }

    if (bankAccountNumberRef.current) {
      bankAccountNumberRef.current.value = "";
    }

    if (paypalAddressRef.current) {
      paypalAddressRef.current.value = "";
    }

    if (cryptoAmountRef.current) {
      cryptoAmountRef.current.value = "";
    }

    if (paypalAmountRef.current) {
      paypalAmountRef.current.value = "";
    }

    if (bankAmountRef.current) {
      bankAmountRef.current.value = "";
    }
  }

  function handleWithdrawalType(e) {
    switch (e.target.value) {
      case "Bank Transfer":
        setAsset("USD");
        setWithdrawalType("Bank Transfer");
        break;
      case "Crypto":
        setAsset("BTC");
        setWithdrawalType("Crypto");
        break;
      case "Paypal":
        setAsset("USD");
        setWithdrawalType("Paypal");
        break;
      default:
        break;
    }

    clear();
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Withdrawal"
            open={[isOpen, setIsOpen]}
          />

          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content>
              <TitleText>Withdrawal</TitleText>
              <ContentWrapper>
                <Card>
                  <SubText className="top">Withdraw</SubText>
                  <ContentContainer>
                    <div className="body">
                      <BodyText>
                        To make a withdrawal, select your balance, amount and
                        verify the address you wish for payment to be made into.
                      </BodyText>

                      {/* <div>
                        <label htmlFor="method">Method</label>
                        <select>
                          <option>Driver's License</option>
                        </select>
                      </div> */}

                      <div className="selectWrap">
                        <label htmlFor="type">Type</label>
                        <div className="selectBox">
                          <div className="selectTop">
                            {/* <span> */}
                            <select name="type" onChange={handleWithdrawalType}>
                              {withdrawalTypes.map((type) => (
                                <option key={type}>{type}</option>
                              ))}
                            </select>
                            <img src="/extraicons/arrowdown.svg" alt="select" />
                            {/* </span> */}
                          </div>
                        </div>
                      </div>

                      {withdrawalType === "Crypto" && (
                        <>
                          <div
                            className="assetSelect"
                            style={{
                              boxSizing: "border-box",
                            }}
                          >
                            <label htmlFor="asset">Asset</label>
                            <div
                              className="selectBox"
                              style={{
                                boxSizing: "border-box",
                                width: "100%",
                              }}
                            >
                              <div className="selectTop">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "relative",
                                    width: "100%",
                                    gap: "0.5rem",
                                    boxSizing: "border-box",
                                    padding: "1rem",
                                  }}
                                >
                                  <img
                                    src={`/asseticons/${asset}.svg`}
                                    alt="icon"
                                    width={24}
                                    height={24}
                                  />
                                  <select
                                    name="asset"
                                    style={{
                                      padding: "0",
                                      width: "100%",
                                      boxSizing: "border-box",
                                      display: "flex",
                                    }}
                                    onChange={handleAssetChange}
                                  >
                                    <option>BTC</option>
                                    <option>ETH</option>
                                    <option>DOGE</option>
                                    <option>BCH</option>
                                    <option>MATIC</option>
                                    <option>USDT</option>
                                    <option>LTC</option>
                                    <option>AVAX</option>
                                    <option>SOL</option>
                                  </select>
                                </div>
                                <img
                                  src="/extraicons/arrowdown.svg"
                                  alt="select"
                                  className="dropDownIcon"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="amWrap">
                            <label htmlFor="amount">Amount</label>
                            <div className="amBx">
                              <div className="amTop">
                                <input
                                  placeholder="1000"
                                  name="amount"
                                  type="number"
                                  ref={cryptoAmountRef}
                                  onChange={handleCryptoAmount}
                                />
                                <span className="coinSelector">
                                  <img
                                    src={`/asseticons/${asset}.svg`}
                                    alt="coin"
                                  />
                                  <p>{asset}</p>
                                </span>
                              </div>

                              <div className="captions">
                                <span className="balance flex">
                                  Balance -
                                  {liveAccounts.map(
                                    (acc) =>
                                      acc.asset === asset && (
                                        <>
                                          {acc.value > 0 && (
                                            <span
                                              key={acc.asset}
                                              className="green"
                                            >
                                              {Number(acc.value).toFixed(2)}{" "}
                                              {asset}
                                            </span>
                                          )}
                                          {acc.value <= 0 && (
                                            <span className="flex gap-1 items-center">
                                              <span
                                                key={acc.asset}
                                                className="red"
                                              >
                                                {Number(acc.value).toFixed(2)}{" "}
                                                {asset}
                                              </span>
                                              <Link to="/deposit">
                                                <p className="red underline">
                                                  Deposit now
                                                </p>
                                              </Link>
                                            </span>
                                          )}
                                        </>
                                      )
                                  )}
                                </span>
                                {amount && (
                                  <p className="extra">
                                    Total in USD
                                    <span className="green">
                                      {" "}
                                      ~$
                                      {Math.floor(
                                        currentPrices[asset] * amount
                                      )}
                                    </span>
                                  </p>
                                )}
                              </div>

                              {cryptoAmountError && (
                                <p
                                  className="red warning textMedium"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Withdrawal value cannot exceed current
                                  balance.
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="addcon">
                            <label htmlFor="new">Address</label>
                            <div className="add">
                              <input
                                ref={cryptoAddressRef}
                                placeholder="Your address"
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </div>
                          </div>

                          <button
                            disabled={!amount || !address || cryptoAmountError}
                            onClick={handleCryptoWithdrawal}
                            className={
                              !amount || !address || cryptoAmountError
                                ? "button disabled"
                                : "button submit"
                            }
                            style={{
                              placeContent: "center",
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                          >
                            {isSubmitting ? (
                              <img
                                src="/svg-loaders/tail-spin.svg"
                                alt="loading"
                                width={24}
                                height={24}
                              />
                            ) : (
                              <p>Withdraw</p>
                            )}
                          </button>
                        </>
                      )}

                      {withdrawalType === "Bank Transfer" && (
                        <>
                          <div className="amWrap">
                            <label htmlFor="amount">Amount</label>
                            <div className="amBx">
                              <div className="amTop">
                                <input
                                  placeholder="1000"
                                  name="amount"
                                  type="number"
                                  ref={bankAmountRef}
                                  onChange={handleBankAmount}
                                />
                                <span className="coinSelector">
                                  <img
                                    src={`/asseticons/${asset}.svg`}
                                    alt="asset"
                                  />
                                  <p>{asset}</p>
                                </span>
                              </div>

                              <div className="captions">
                                <span className="balance flex gap-1">
                                  Current Balance ~ {""}
                                  {liveAccounts.map(
                                    (acc) =>
                                      acc.asset === asset && (
                                        <span
                                          key={acc.asset}
                                          className="flex green"
                                        >
                                          {acc.value > 0 &&
                                            `${Number(acc.value).toFixed(
                                              2
                                            )} ${asset} `}

                                          {acc.value <= 0 && (
                                            <span className="flex gap-1 red">
                                              {Number(acc.value).toFixed(2)}{" "}
                                              {asset}
                                              <Link to="/deposit">
                                                <p className="red underline">
                                                  Deposit now
                                                </p>
                                              </Link>
                                            </span>
                                          )}
                                        </span>
                                      )
                                  )}
                                </span>

                                {amount && (
                                  <p className="extra">
                                    Total in USD
                                    <span className="green">
                                      {" "}
                                      ~${currentPrices[asset] * amount}
                                    </span>
                                  </p>
                                )}
                              </div>
                              {bankAmountError && (
                                <p
                                  className="red warning textMedium"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Withdrawal value cannot exceed current
                                  balance.
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="addcon">
                            <label htmlFor="new">Bank Name</label>
                            <div className="add">
                              <input
                                ref={bankAccountRef}
                                placeholder="Chase Bank"
                                onChange={(e) => setBankName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="addcon">
                            <label htmlFor="new">Account Name</label>
                            <div className="add">
                              <input
                                ref={bankAccountNameRef}
                                placeholder="John Doe"
                                onChange={(e) => setAccountName(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="addcon">
                            <label htmlFor="new">Account Number</label>
                            <div className="add">
                              <input
                                ref={bankAccountNumberRef}
                                placeholder="29930192"
                                onChange={(e) =>
                                  setAccountNumber(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <OutlinedBox>
                            <p className="infot">
                              Your Account Manager may request further
                              information.
                            </p>
                          </OutlinedBox>

                          <button
                            disabled={
                              !amount ||
                              !bankName ||
                              bankAmountError ||
                              !accountName ||
                              !accountNumber
                            }
                            className={
                              !amount ||
                              !bankName ||
                              bankAmountError ||
                              !accountName ||
                              !accountNumber
                                ? "button disabled"
                                : "button submit"
                            }
                            onClick={handleBankWithdrawal}
                            style={{
                              placeContent: "center",
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                          >
                            {isSubmitting ? (
                              <img
                                src="/svg-loaders/tail-spin.svg"
                                alt="loading"
                                width={24}
                                height={24}
                              />
                            ) : (
                              <p>Withdraw</p>
                            )}
                          </button>
                        </>
                      )}

                      {withdrawalType === "Paypal" && (
                        <>
                          <div className="amWrap">
                            <label htmlFor="amount">Amount</label>
                            <div className="amBx">
                              <div className="amTop">
                                <input
                                  placeholder="1000"
                                  name="amount"
                                  type="number"
                                  ref={paypalAmountRef}
                                  onChange={handlePaypalAmount}
                                />
                                <span className="coinSelector">
                                  <img
                                    src={`/asseticons/${asset}.svg`}
                                    alt="asset"
                                  />
                                  <p>{asset}</p>
                                </span>
                              </div>

                              <div className="captions">
                                <span className="balance flex gap-1">
                                  Current Balance ~ {""}
                                  {liveAccounts.map(
                                    (acc) =>
                                      acc.asset === asset && (
                                        <span
                                          key={acc.asset}
                                          className="flex green"
                                        >
                                          {acc.value > 0 &&
                                            `${Number(acc.value).toFixed(
                                              2
                                            )} ${asset} `}

                                          {acc.value <= 0 && (
                                            <span className="flex gap-1 red">
                                              {Number(acc.value).toFixed(2)}{" "}
                                              {asset}
                                              <Link to="/deposit">
                                                <p className="red underline">
                                                  Deposit now
                                                </p>
                                              </Link>
                                            </span>
                                          )}
                                        </span>
                                      )
                                  )}
                                </span>
                                {amount && (
                                  <p className="extra">
                                    Total in USD
                                    <span className="green">
                                      {" "}
                                      ~${currentPrices[asset] * amount}
                                    </span>
                                  </p>
                                )}
                              </div>

                              {paypalAmountError && (
                                <p
                                  className="red warning textMedium"
                                  style={{ marginTop: "1rem" }}
                                >
                                  Withdrawal value cannot exceed current
                                  balance.
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="addcon">
                            <label htmlFor="new">Paypal Address</label>
                            <div className="add">
                              <input
                                ref={paypalAddressRef}
                                type="email"
                                placeholder="johndoe@gmail.com"
                                onChange={(e) =>
                                  setPaypalAddress(e.target.value)
                                }
                              />
                            </div>
                          </div>

                          <button
                            disabled={
                              !amount || paypalAmountError || !paypalAddress
                            }
                            onClick={handlePaypalWithdrawal}
                            className={
                              !amount || paypalAmountError || !paypalAddress
                                ? "button disabled"
                                : "button submit"
                            }
                            style={{
                              placeContent: "center",
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                            }}
                          >
                            {isSubmitting ? (
                              <img
                                src="/svg-loaders/tail-spin.svg"
                                alt="loading"
                                width={24}
                                height={24}
                              />
                            ) : (
                              <p>Withdraw</p>
                            )}
                          </button>
                        </>
                      )}
                      {/* <div className="amWrap">
                        <label htmlFor="amount">Amount</label>
                        <div className="amBx">
                          <div className="amTop">
                            <input
                              placeholder="1000"
                              name="amount"
                              type="number"
                              onChange={handleAmountChange}
                              style={{ padding: "1rem" }}
                            />
                            <span className="coinSelector">
                              <img src={`/asseticons/${coin}.svg`} alt="coin" />
                              <p>{coin}</p>
                            </span>
                          </div>

                          <div className="captions">
                            <p className="red balance">
                              Amount cannot exceed your balance
                              Current balance - {""}
                              {liveAccounts.map(
                                (acc) =>
                                  acc.asset === coin && (
                                    <span>
                                      {acc.value} {coin}
                                    </span>
                                  )
                              )}
                            </p>
                            <p className="extra">
                              Total in USD
                              <span className="green"> ~$80</span>
                            </p>
                          </div>
                        </div>
                      </div> */}
                      {/* 
                      <InputNew>
                        <label for="new">New</label>
                        <div className="inputContainer">
                          <div className="inputTop">
                            <div className="left">BTC</div>
                            <input placeholder="0.01" />
                          </div>
                        </div>
                        <p>Current Balance - 0.03</p>
                      </InputNew> */}
                      {/* 
                      <InputJustified>
                        <div className="desc">Asset</div>
                        <input placeholder="0.01" />
                      </InputJustified> */}
                    </div>
                  </ContentContainer>
                </Card>
              </ContentWrapper>
              <WithdrawalHistory user={user} />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;

  button:disabled {
    background-color: gray !important;
    cursor: not-allowed !important;
  }

  .warning {
    font-size: 14px;
  }

  .assetSelect {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      background-color: #1f273a;
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      .dropDownIcon {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }
`;

const Main = styled.div`
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const ContentContainer = styled.div`
  padding: 1rem;
  text-align: left;
  display: grid;

  .body {
    max-width: 453px;
    place-self: center;
    box-sizing: border-box;
    padding: 1rem;
    gap: 1rem;
    display: grid;

    @media screen and (max-width: 800px) {
      max-width: 100%;
    }
  }

  button {
    margin-top: 20px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 50px;
`;

export default Withdraw;
